export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [0];

export const dictionary = {
		"/api/login": [21],
		"/api/profile": [22],
		"/[lang]": [5,[2]],
		"/[lang]/(private)/_/questionnaire/[id]": [~6,[2,3]],
		"/[lang]/(private)/booking": [~7,[2,3]],
		"/[lang]/(private)/booking/edit/[type]/[booking_id]": [11,[2,3]],
		"/[lang]/(private)/booking/[booking_category]": [8,[2,3]],
		"/[lang]/(private)/booking/[booking_category]/[type]": [~9,[2,3,4]],
		"/[lang]/(private)/booking/[booking_category]/[type]/[category]/[question]": [10,[2,3,4]],
		"/[lang]/(private)/followup": [~12,[2,3]],
		"/[lang]/(public)/login_phone": [17,[2]],
		"/[lang]/(public)/login": [~16,[2]],
		"/[lang]/(public)/privacy": [~18,[2]],
		"/[lang]/(private)/questionnaire": [~13,[2,3]],
		"/[lang]/(private)/report/[id]": [~14,[2,3]],
		"/[lang]/(public)/signout": [19,[2]],
		"/[lang]/(private)/summary/[id]": [~15,[2,3]],
		"/[lang]/(public)/terms": [~20,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';